import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Grid,
  Link,
  Typography,
  Divider,
  useTheme,
} from '@mui/material';

import { ShopCardProps } from './types';
import AdCard from '@/components/common/AdCard';
import { baseUrl, defaultShopPic } from '@/config';

const ShopCard: React.FunctionComponent<ShopCardProps> = ({
  shop, ads, index, link = 'bolt',
}) => {
  const theme = useTheme();
  const highlighted = shop.paidPosition;

  return (
    <Card
      elevation={1}
      sx={[
        highlighted && { border: `1px solid ${theme.palette.primary.main}` },
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        },
      ]}
    >
      <CardContent sx={{ p: 1, width: '100%', '&:last-child': { pb: 1 } }}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              mr: 2,
            }}
          >
            <Link
              href={`${baseUrl}/${link}/${shop.id}`}
              sx={{
                color: 'text.primary',
                underline: 'none',
                width: '66px',
                height: '66px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={shop.image || defaultShopPic}
                alt={shop.name}
                loading={index > 4 ? 'lazy' : 'eager'}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Link>

          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                height: '40px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {shop.name}
            </Typography>
            <Button
              variant="text"
              color="secondary"
              sx={{ justifyContent: 'flex-start', lineHeight: '22px' }}
              endIcon={<KeyboardArrowRightOutlinedIcon sx={{ width: '18px', height: '18px' }} />}
              href={`${baseUrl}/${link}/${shop.id}`}
              aria-label={`${shop.name} (${shop.itemCount} hirdetés)`}
              size="small"
            >
              {shop.itemCount}
              {' '}
              hirdetés
            </Button>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Stack direction="row" spacing={4}>
          <Grid
            container
            wrap="nowrap"
            sx={{
              columnGap: 4,
              flexDirection: 'row',
              minWidth: 0,
            }}
          >
            {ads
              && (
                <>
                  {ads[0] && (
                    <Grid item xs={12} lg={6} sx={{ minWidth: 0 }}>
                      <AdCard
                        product={ads[0]}
                        itemIndex={0}
                        noBorder
                        trackingParams="#pos=boltok_tile&opt=homepage"
                      />
                    </Grid>
                  )}
                  {ads[1]
                    && (
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                          display: 'block',
                          minWidth: 0,
                        }}
                      >
                        <AdCard
                          product={ads[1]}
                          itemIndex={1}
                          noBorder
                          trackingParams="#pos=boltok_tile&opt=homepage"
                        />
                      </Grid>
                    )}
                </>
              )}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ShopCard;
