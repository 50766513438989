import CustomAlert from './CustomAlert';
import { InfoBoxProps } from './types';

// The alert under the BlueBar on vertical pages
const InfoBox: React.FunctionComponent<InfoBoxProps> = ({ alert }) => {
  if (alert) {
    return (
      <CustomAlert
        key={alert.text}
        text={alert.text}
        type={alert.type}
        cta={alert.cta}
      />
    );
  }

  return null;
};

export default InfoBox;
