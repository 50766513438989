import {
  Alert, Button, Container, Grid, Typography,
} from '@mui/material';
import { AlertProps } from '@mui/material/Alert';
import { styled, useTheme } from '@mui/material/styles';

import { CustomAlertProps } from '@/components/common/InfoBox/types';
import { clickInfoBoxLink } from '@/helpers/tracking/events';

interface StyledAlertProps extends AlertProps {
  children: React.ReactNode;
}

const StyledAlert = styled((props: StyledAlertProps) => <Alert {...props} />)(() => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
}));

// InfoBox or AlertBox, the alert under the BlueBar on vertical pages
const CustomAlert: React.FunctionComponent<CustomAlertProps> = ({
  text, type, cta,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette[type].background,
      }}
    >
      <Container>
        <StyledAlert
          severity={type}
          sx={{ backgroundColor: `${type}.background` }}
          action={(
            cta && (
              <Button
                href={cta.url}
                onClick={(): void => clickInfoBoxLink(cta.text)}
                variant="text"
                size="small"
                target="_blank"
                color={`${type}` as CustomAlertProps['type']}
              >
                {cta.text}
              </Button>
            )
          )}
        >
          <Typography
            variant="body2"
            color="text.primary"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </StyledAlert>
      </Container>
    </Grid>
  );
};

export default CustomAlert;
