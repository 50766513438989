import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { PopularCategoriesProp } from './types';
import Headline from '@/components/adView/common/Headline';
import { PopularCategory } from '@/data/static/home/types';

const PopularCategories = ({ categories }: PopularCategoriesProp): React.ReactElement => {
  const theme = useTheme();

  return (
    <Container id="category-results">
      <Headline>Népszerű kategóriák</Headline>
      <Grid
        id="ob-popular"
        container
        columnSpacing={{ xs: 2, md: 4 }}
        rowSpacing={4}
      >
        {categories.map((item: PopularCategory) => (
          <Grid
            item
            key={item.id}
            xs={6}
            md={4}
            lg={2}
            sx={{
              '&:hover': {
                color: theme.palette.primary.main,
              },
            }}
            flexGrow={1}
          >
            <Link
              data-testid="home-main-category"
              href={`${item.url}#pos=popular_categories&opt=homepage`}
              variant="inherit"
              underline="none"
              sx={{
                textDecoration: 'none',
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="column"
                height="100%"
                flexWrap="unset"
                sx={{
                  '&:hover': {
                    '& .imageContainer': {
                      boxShadow: 4,
                    },
                    '& .typography': {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
              >
                <Box
                  height="128px"
                  width="128px"
                  borderRadius="50%"
                  overflow="hidden"
                  mb={2}
                  className="imageContainer"
                  sx={{
                    boxShadow: 3,
                  }}
                >
                  <img
                    src={item.imgUrl}
                    height="100%"
                    width="100%"
                    alt={item.label}
                  />
                </Box>
                <Box display="flex" flexGrow={1} alignItems="center" flexWrap="wrap">
                  <Typography
                    variant="body1"
                    align="center"
                    lineHeight={1.5}
                    className="typography"
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Stack>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PopularCategories;
