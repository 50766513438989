import { PopularCategory } from '../types';
import { baseUrl } from '@/config';

const popularCategoriesData: PopularCategory[] = [
  {
    label: 'Szabadidő, sport',
    url: `${baseUrl}/magyarorszag/szabadido-sport`,
    imgUrl: '/assets/home/popular-categories/szabadido_sport.png',
    id: 4000,
  },
  {
    label: 'Műszaki cikkek, elektronika',
    url: `${baseUrl}/magyarorszag/muszaki-cikkek-elektronika`,
    imgUrl: '/assets/home/popular-categories/muszaki_cikk.png',
    id: 5000,
  },
  {
    label: 'Otthon, háztartás',
    url: `${baseUrl}/magyarorszag/otthon-haztartas`,
    imgUrl: '/assets/home/popular-categories/otthon_haztartas.png',
    id: 3000,
  },
  {
    label: 'Divat, ruházat',
    url: `${baseUrl}/magyarorszag/divat-ruhazat`,
    imgUrl: '/assets/home/popular-categories/divat_ruhazat.png',
    id: 8000,
  },
  {
    label: 'Baba-mama',
    url: `${baseUrl}/magyarorszag/baba-mama`,
    imgUrl: '/assets/home/popular-categories/baba_mama.png',
    id: 8060,
  },
  {
    label: 'Üzlet, szolgáltatás',
    url: `${baseUrl}/magyarorszag/uzlet-szolgaltatas`,
    imgUrl: '/assets/home/popular-categories/szolgaltatas.png',
    id: 9000,
  },
];

export default popularCategoriesData;
