import { fetchAds } from '@/data/client/ads';

const getAdsForShops = async (shops) => {
  const shopIds = [];
  const ads = [];

  shops.forEach((shop) => {
    shopIds.push(shop.id);
  });

  const fetches = shopIds.map((shopId) => fetchAds({
    query: {
      shop_list_ids: shopId,
      fields_mode: 'lite',
      limit: 2,
    },
  }));

  (await Promise.allSettled(fetches)).forEach((response, index) => {
    if (response.status === 'fulfilled') {
      response.value.forEach((ad) => {
        ads.push({
          ...ad,
          shop_list_id: shopIds[index],
        });
      });
    }
  });

  return ads;
};

export default getAdsForShops;
