import { Grid, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';

import { GeneralistHomeProps } from '../types/generalist';
import BluebarLinks from '@/components/common/BluebarLinks/BluebarLinks';
import ComponentErrorBoundary from '@/components/common/ComponentErrorBoundary/ComponentErrorBoundary';
import Head from '@/components/common/Head';
import InfoBox from '@/components/common/InfoBox';
import ActionCards from '@/components/home/ActionCards';
import FreshAds from '@/components/home/FreshAds/FreshAds';
import Hero from '@/components/home/Hero';
import PopularCategories from '@/components/home/PopularCategories/PopularCategories';
import JfgShops from '@/components/home/Shops/index.server';
import Tiles from '@/components/home/Tiles/Tiles';
import VerticalBackground from '@/components/home/VerticalBackground/VerticalBackground';
import popularCategoriesData from '@/data/static/home/generalist/popularCategoriesData';

const LastSeenAds = dynamic(() => import('@/components/home/LastSeenAds'), { ssr: false });
const JfgSearchbar = dynamic(() => import('@/components/home/SearchBar'), { ssr: false });
const JfgAdverticumZone = dynamic(
  () => import('@/components/marketing/AdverticumZone'),
);

const GeneralistHome = ({
  freshAds, megaMenuData, heroData, shopData, infoBoxAlert,
}: GeneralistHomeProps): React.ReactNode => {
  const theme = useTheme();
  return (
    <>
      <ComponentErrorBoundary componentName="Head">
        <Head
          title="Jófogás - Apróhirdetések, használt termékek"
          description="Vedd tovább! Tedd pénzzé nem használt dolgaidat Magyarország legnagyobb online apróhirdetési oldalán!"
          canonical=""
          productJsonLdData={undefined}
        />
      </ComponentErrorBoundary>
      <ComponentErrorBoundary componentName="Bluebar">
        <BluebarLinks active="main" />
      </ComponentErrorBoundary>
      <ComponentErrorBoundary componentName="InfoBox">
        <InfoBox alert={infoBoxAlert} />
      </ComponentErrorBoundary>
      <VerticalBackground
        backgroundUrl="/assets/searchbar/homepage_pattern.svg"
      >
        <ComponentErrorBoundary componentName="JfgSearchbar">
          <JfgSearchbar megaMenuData={megaMenuData} />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="ActionCards">
          <ActionCards />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="Hero">
          <Hero heroData={heroData || []} />
        </ComponentErrorBoundary>
      </VerticalBackground>
      <Grid
        container
        sx={{ bgcolor: theme.palette.background.default }}
        flexDirection="column"
        rowGap={4}
        pb={8}
      >
        <ComponentErrorBoundary componentName="PopularCategories">
          <PopularCategories categories={popularCategoriesData} />
        </ComponentErrorBoundary>
        {/* Known issue: this causes CLS because it relies on the userState */}
        <ComponentErrorBoundary componentName="LastSeenAds">
          <LastSeenAds />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="JfgAdverticumZone">
          <JfgAdverticumZone style={{ textAlign: 'center', minHeight: '110px' }} pageType="indexPage" zoneName="index_inbetween" displayOn="desktop" />
          <JfgAdverticumZone style={{ textAlign: 'center', minHeight: '300px' }} pageType="indexPage" zoneName="index_inbetween" displayOn="mobile" />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="FreshAds">
          <FreshAds freshAds={freshAds} />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="Tiles">
          <Tiles />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="JfgShops">
          <JfgShops shops={shopData?.shops || { shops: [] }} />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary componentName="JfgAdverticumZone">
          <JfgAdverticumZone
            style={{ textAlign: 'center' }}
            pageType="indexPage"
            zoneName="index_bottom"
            displayOn="mobile"
          />
        </ComponentErrorBoundary>
      </Grid>
    </>
  );
};

export default GeneralistHome;
